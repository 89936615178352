import { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { Avatar, IconButton } from "@mui/material";
import { Link } from "iconsax-react";
import { useSearchParams } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { Tabs, Card, Heading, SimpleGrid } from "@chakra-ui/react";
import axios from "axios";
import Actions from "../../components/SessionRecording/Actions";
import Player from "../../components/SessionRecording/Player";
import { ProgressRoot, ProgressBar } from "../../components/ui/progress";

export default function SessionRecording() {
  const [searchParams] = useSearchParams();
  const [sessionRecording, setSessionRecording] = useState(null);
  const [
    currentSessionRecordingEventTimestamp,
    setCurrentSessionRecordingEventTimestamp,
  ] = useState(Date.now());
  const [currentSessionRecordingEvent, setCurrentSessionRecordingEvent] =
    useState(null);
  const [playerInstance, setPlayerInstance] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [progressInPercentage, setProgressInPercentage] = useState(null);
  useEffect(() => {
    // Fetch the video data from Cloudinary
    async function setup() {
      const response = await axios({
        method: "GET",
        url: `https://api.usabul.io/session-recordings/${searchParams.get("id")}/find`,
        validateStatus: () => true,
        onDownloadProgress: (progressEvent) => {
          console.log(
            `progress: ${progressEvent.loaded} of ${progressEvent.total}`
          );
          setProgressInPercentage(
            (progressEvent.loaded / progressEvent.total) * 100
          );
          if (progressEvent.loaded === progressEvent.total) {
          }
        },
      });

      if (response.status === 200) {
        console.log("SESSION RECORDING: ", response.data.data.sessionRecording);
        setCurrentSessionRecordingEventTimestamp(
          response.data.data.sessionRecording.events[0].timestamp
        );
        setSessionRecording(response.data.data.sessionRecording);
      }
      setIsLoading(false);
    }
    setup();
  }, []);

  if (isLoading === true) {
    return (
      <div className="h-screen w-screen flex flex-col items-center justify-center">
        <p className="text-sm text-gray-400 my-3">
          Pulling session recording data ...
        </p>
        <ProgressRoot
          striped
          animated
          value={progressInPercentage}
          w={"sm"}
          colorPalette={"blue"}
          size={"xs"}
        >
          <ProgressBar />
        </ProgressRoot>
      </div>
    );
  }

  if (sessionRecording) {
    return (
      <div className="h-screen w-screen overflow-hidden">
        <div
          className={
            "fixed top-0 right-0 left-0 flex h-min-content justify-between p-3 border-b border-b-gray-200 bg-white h-[60px]"
          }
        >
          <div>
            <Avatar
              src={
                "https://res.cloudinary.com/geergregrgege/image/upload/v1722773827/assets/images/usabul_logo_icon_white_bciudc.png"
              }
            />
          </div>
          <div className={"flex"}>
            <IconButton
              onClick={() => {
                enqueueSnackbar("Copied to clipboard!");
              }}
            >
              <Link />
            </IconButton>
          </div>
        </div>
        <div className={"w-full h-full max-h-full pt-[60px]"}>
          <div className={"flex w-full h-full max-h-full"}>
            <div
              className={
                "w-[90%] md:w-[60%] lg:w-[80%] xl:w-[90%] flex flex-col h-full max-h-full bg-gray-100"
              }
            >
              <div className="flex justify-center h-min bg-gray-50 py-3 border-b border-b-gray-300">
                <p className="text-sm text-gray-600">
                  {`https://${currentSessionRecordingEvent?.location?.hostname}${currentSessionRecordingEvent?.location?.pathname}${currentSessionRecordingEvent?.location?.search}`}
                </p>
              </div>
              <div className="flex-grow">
                <Player
                  sessionRecording={sessionRecording}
                  setCurrentSessionRecordingEvent={
                    setCurrentSessionRecordingEvent
                  }
                  currentSessionRecordingEventTimestamp={
                    currentSessionRecordingEventTimestamp
                  }
                  setCurrentSessionRecordingEventTimestamp={
                    setCurrentSessionRecordingEventTimestamp
                  }
                  playerInstance={playerInstance}
                  setPlayerInstance={setPlayerInstance}
                />
              </div>
            </div>
            <div
              className={
                "w-[10%] md:w-[40%] lg:w-[20%] xl:w-[10%] flex flex-col overflow-y-scroll no-scrollbar border-l border-l-gray-300"
              }
            >
              <div>
                <Tabs.Root defaultValue="info" variant="plain" size={"sm"}>
                  <Tabs.List bg="bg.muted" rounded="l3" p="1" m={"3"}>
                    <Tabs.Trigger fontSize={"sm"} px={"4"} value="info">
                      Info
                    </Tabs.Trigger>
                    <Tabs.Trigger fontSize={"sm"} px={"4"} value="events">
                      Events
                    </Tabs.Trigger>
                    <Tabs.Indicator rounded="l2" />
                  </Tabs.List>
                  <Tabs.Content value="info" p={"0"}>
                    <SimpleGrid
                      columns={[2, null, 2]}
                      gap="3"
                      p={"3"}
                      className={""}
                    >
                      <Card.Root size="sm" variant={"outline"}>
                        <Card.Header>
                          <p className={"text-2xl"}>0</p>
                        </Card.Header>
                        <Card.Body color="fg.muted">Events</Card.Body>
                      </Card.Root>
                      <Card.Root size="sm" variant={"outline"}>
                        <Card.Header>
                          <p className={"text-2xl"}>0</p>
                        </Card.Header>
                        <Card.Body color="fg.muted">Clicks</Card.Body>
                      </Card.Root>
                    </SimpleGrid>
                  </Tabs.Content>
                  <Tabs.Content value="events">
                    <Actions
                      sessionRecording={sessionRecording}
                      currentSessionRecordingEventTimestamp={
                        currentSessionRecordingEventTimestamp
                      }
                      playerInstance={playerInstance}
                      setPlayerInstance={setPlayerInstance}
                    />
                  </Tabs.Content>
                </Tabs.Root>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
