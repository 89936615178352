import { Divider } from "@mui/material";
import { useEffect, useState } from "react";
import { Navigate, useSearchParams, useNavigate } from "react-router-dom";
import { Table, HStack } from "@chakra-ui/react";
import { Checkbox } from "../../../../components/ui/checkbox";
import { Button } from "../../../../components/ui/button";
import {
  PaginationItems,
  PaginationNextTrigger,
  PaginationPrevTrigger,
  PaginationRoot,
} from "../../../../components/ui/pagination";
import axios from "axios";
import { Play } from "iconsax-react";
import moment from "moment";

function getNumberOfPagesFromEvents(events) {
  let pages = new Set();
  events.forEach((event) => {
    pages.add(event.location.pathname);
  });
  return pages.size;
}

function getDurationFromEvents(events) {
  if (events.length === 0 || events.length === 1) {
    return "00:00:00";
  }
  let duration = events[events.length - 1].timestamp - events[0].timestamp;
  return moment.utc(duration).format("HH:mm:ss");
}

export default function SessionRecordings() {
  const navigate = useNavigate();
  const [sessionRecordings, setSessionRecordings] = useState([]);
  const [selection, setSelection] = useState([]);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [numberOfSessionRecordings, setNumberOfSessionRecordings] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentCount, setCurrentCount] = useState(10);

  const hasSelection = selection.length > 0;
  const indeterminate =
    hasSelection && selection.length < sessionRecordings.length;
  const [searchParams] = useSearchParams(); 

  useEffect(() => {
    async function setup() {
      const response = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_API_BASE_URL}/projects/${searchParams.get(
          "id"
        )}/session-recordings/find?count=${currentCount}&page=${currentPage}`,
      });

      if (response.status === 200) {
        setNumberOfSessionRecordings(
          response.data.data.numberOfSessionRecordings
        );
        setNumberOfPages(response.data.data.numberOfPages);
        setSessionRecordings(response.data.data.sessionRecordings);
      } else {
        console.error(response.data);
      }
    }
    setup();
  }, [currentCount, currentPage, searchParams]);

  return (
    <>
      <div>
        <h1 className="text-2xl font-semibold mt-6">Session Recordings</h1>
        <p className="text-xs font-light text-gray-600"></p>
      </div>
      <div className="w-full rounded-md mt-6">
        <Divider />
        <Table.Root variant="outline" showColumnBorder={true} size={"md"}>
          <Table.Header>
            <Table.Row>
              {/* <Table.ColumnHeader w="6">
                <Checkbox
                  top="1"
                  aria-label="Select all rows"
                  checked={
                    indeterminate ? "indeterminate" : selection.length > 0
                  }
                  onCheckedChange={(changes) => {
                    setSelection(
                      changes.checked
                        ? sessionRecordings.map((item) => item.name)
                        : []
                    );
                  }}
                />
              </Table.ColumnHeader> */}
              <Table.ColumnHeader></Table.ColumnHeader>
              <Table.ColumnHeader>Date</Table.ColumnHeader>
              <Table.ColumnHeader>ID</Table.ColumnHeader>
              <Table.ColumnHeader>Highlights</Table.ColumnHeader>
              <Table.ColumnHeader>Actions</Table.ColumnHeader>
              <Table.ColumnHeader>Pages</Table.ColumnHeader>
              <Table.ColumnHeader>Duration</Table.ColumnHeader>
              <Table.ColumnHeader>Landing Page</Table.ColumnHeader>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {sessionRecordings.map((item) => {
              return (
                <Table.Row key={item.id}>
                  {/* <Table.Cell>
                  <Checkbox
                    top="1"
                    aria-label="Select row"
                    checked={selection.includes(item.name)}
                    onCheckedChange={(changes) => {
                      setSelection((prev) =>
                        changes.checked
                          ? [...prev, item.name]
                          : selection.filter((name) => name !== item.name)
                      );
                    }}
                  />
                </Table.Cell> */}

                  {item.events.length < 1 ? (
                    <Table.Cell>
                      <Button
                        disabled={true}
                        px={3}
                        variant={"surface"}
                        color={"blue"}
                      >
                        <Play /> Play
                      </Button>
                    </Table.Cell>
                  ) : (
                    <Table.Cell>
                      <Button
                        px={3}
                        variant={"surface"}
                        color={"blue"}
                        onClick={() => {
                          window.open(
                            `/session-recording?id=${item._id}`,
                            "_blank"
                          );
                        }}
                      >
                        <Play /> Play
                      </Button>
                    </Table.Cell>
                  )}

                  <Table.Cell>
                    {moment(item.createdAt).format("MMMM Do, YYYY")}
                  </Table.Cell>
                  <Table.Cell>{item._id}</Table.Cell>
                  <Table.Cell>-</Table.Cell>
                  {item.events.length < 1 ? (
                    <Table.Cell>-</Table.Cell>
                  ) : (
                    <Table.Cell>{item.events.length}</Table.Cell>
                  )}

                  {item.events.length < 1 ? (
                    <Table.Cell>-</Table.Cell>
                  ) : (
                    <Table.Cell>
                      {getNumberOfPagesFromEvents(item.events)}
                    </Table.Cell>
                  )}
                  {item.events.length < 1 ? (
                    <Table.Cell>-</Table.Cell>
                  ) : (
                    <Table.Cell>
                      {getDurationFromEvents(item.events)}
                    </Table.Cell>
                  )}
                  {item.events.length < 1 ? (
                    <Table.Cell>-</Table.Cell>
                  ) : (
                    <Table.Cell>{item.events[0].location.pathname}</Table.Cell>
                  )}
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table.Root>
        <PaginationRoot
          count={numberOfSessionRecordings}
          pageSize={currentCount}
          defaultPage={1}
          size={"md"}
          onPageChange={(page) => {
            setCurrentPage(page.page);
          }}
        >
          <HStack>
            <PaginationPrevTrigger />
            <PaginationItems />
            <PaginationNextTrigger />
          </HStack>
        </PaginationRoot>
      </div>
    </>
  );
}
