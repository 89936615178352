import "./App.css";
import { useMemo } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import pages from "./pages";
import Onboard from "./pages/onboard";
import Home from "./pages/dashboard/Home";
import Inbox from "./pages/dashboard/Inbox";
import Users from "./pages/dashboard/Users";
import Boards from "./pages/project/feedback/boards";
import Feedback from "./pages/project/feedback/feedback";
import Analytics from "./pages/project/feedback/analytics";
import Installation from "./pages/project/widgets/installation";
import MemberForm from "./pages/project/widgets/member-form";
import GuestForm from "./pages/project/widgets/guest-form";
import Button from "./pages/project/widgets/button";
import SessionRecordings from "./pages/project/session-recording/session-recordings";
import General from "./pages/project/settings/General.jsx";
import Profile from "./pages/settings/personal/profile";
import Members from "./pages/settings/workspace/Members";
import SettingsWorkspaceGeneral from "./pages/settings/workspace/General";
import Invitation from "./pages/workspace/invitation";
import Verify from "./pages/email-address/verify";
import Reset from "./pages/password/reset";
import ScreenCaptureVideo from "./pages/screen-capture/Video";
import SessionRecording from "./pages/session-recording";
import LayoutV1 from "./layouts/LayoutV1";
import DashboardLayoutV1 from "./layouts/DasboardLayoutV1";
import ProjectLayoutV1 from "./layouts/ProjectLayoutV1";
import SettingsLayoutV1 from "./layouts/SettingsLayoutV1";
import { Provider } from "react-redux";
import store from "./store";
import {
  ChakraProvider as ThemeProvider2,
  defaultSystem,
} from "@chakra-ui/react";

function App() {
  const theme = useMemo(
    () =>
      createTheme({
        components: {
          MuiButton: {
            styleOverrides: {
              root: {
                borderColor: "black",
                textTransform: "none",
              },
            },
          },
          MuiTab: {
            styleOverrides: {
              root: {
                textTransform: "none",
              },
            },
          },
          MuiListItemText: {
            styleOverrides: {
              primary: {
                fontSize: "0.8rem",
                fontWeight: "400",
              },
              secondary: {
                fontSize: "0.875rem",
                fontWeight: "300",
              },
            },
          },
        },
      }),
    []
  );
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <ThemeProvider2 value={defaultSystem}>
          <SnackbarProvider maxSnack={10}>
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<Navigate to="/sign-in" />} />
                <Route
                  path="/screen-capture/video"
                  element={<ScreenCaptureVideo />}
                />
                <Route
                  path="/session-recording"
                  element={<SessionRecording />}
                />
                <Route path="/onboard" element={<Onboard />} />
                <Route element={<LayoutV1 />}>
                  <Route path="/password/reset" element={<Reset />} />
                  <Route path="/email-address/verify" element={<Verify />} />
                  <Route path="/sign-in" element={<pages.SignIn />} />
                  <Route path="/sign-up" element={<pages.SignUp />} />
                </Route>
                <Route
                  path={"/dashboard"}
                  element={<Navigate to={"/dashboard/home"} />}
                />
                <Route element={<DashboardLayoutV1 />}>
                  <Route path={"/dashboard/home"} element={<Home />} />
                  <Route path={"/dashboard/inbox"} element={<Inbox />} />
                  <Route path={"/dashboard/users"} element={<Users />} />
                </Route>
                <Route element={<ProjectLayoutV1 />}>
                  <Route
                    path={"/project"}
                    element={<Navigate to={"/project/feedback/feedbacks"} />}
                  />
                  <Route
                    path={"project/feedback/feedback"}
                    element={<Feedback />}
                  />
                  <Route
                    path={"project/feedback/analytics"}
                    element={<Analytics />}
                  />
                  <Route
                    path={"project/widget/installation"}
                    element={<Installation />}
                  />
                  <Route path={"project/widget/button"} element={<Button />} />
                  <Route
                    path={"project/widget/member-form"}
                    element={<MemberForm />}
                  />
                  <Route
                    path={"project/widget/guest-form"}
                    element={<GuestForm />}
                  />
                  <Route
                    path={"project/session-recording/session-recordings"}
                    element={<SessionRecordings />}
                  />
                  <Route
                    path={"project/settings/general"}
                    element={<General />}
                  />
                </Route>
                <Route element={<SettingsLayoutV1 />}>
                  <Route
                    path={"/settings"}
                    element={<Navigate to={"/settings/personal/profile"} />}
                  />
                  <Route
                    path={"settings/personal/profile"}
                    element={<Profile />}
                  />
                  <Route
                    path={"/settings/workspace/general"}
                    element={<SettingsWorkspaceGeneral />}
                  />
                  <Route
                    path={"/settings/workspace/members"}
                    element={<Members />}
                  />
                </Route>
                <Route
                  path={"/workspace/invitation"}
                  element={<Invitation />}
                />
              </Routes>
            </BrowserRouter>
          </SnackbarProvider>
        </ThemeProvider2>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
