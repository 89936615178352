import moment from "moment";
import clsx from "clsx";
import { Document, TableDocument } from "iconsax-react";

const eventTypeMap = {
  0: "DomContentLoaded",
  1: "Load",
  2: "FullSnapshot",
  3: "IncrementalSnapshot",
  4: "Meta",
  5: "Custom",
};

// Function to get a human-readable name based on event details
const getReadableEventName = (event) => {
  if (event.type === 3 && event.data) {
    if (event.data.type === 2) {
      return "Clicked";
    } else if (event.data.source === 5) {
      return "Input Changed";
    } else if (
      event.data.source === 4 &&
      event.data.width &&
      event.data.height
    ) {
      return "Resized";
    }
  }
  return eventTypeMap[event.type] || "Unknown Event";
};

export default function Actions(props) {
  return (
    <div className="px-3">
      {props.sessionRecording.events.map((event, index) => {
        if (index === 0) {
          return (
            <div className="py-4 flex gap-x-3 items-center">
              <TableDocument className="h-5 w-5 text-gray-400" />
              <p className="text-xs font-semibold">{event.location.pathname}</p>
            </div>
          );
        } else {
          if (
            event.location.pathname !==
            props.sessionRecording.events[index - 1].location.pathname
          ) {
            return (
              <div className="py-4 flex gap-x-3 items-center">
                <TableDocument className="h-5 w-5 text-gray-400" />
                <p className="text-xs font-semibold">
                  {event.location.pathname}
                </p>
              </div>
            );
          }
        }
        if (event.type === 3) {
          if (
            event.data.type === 2 ||
            event.data.source === 5 ||
            event.data.source === 4
          ) {
            return (
              <div
                key={index}
                className={clsx(
                  "cursor-pointer hover:border-blue-700 p-2 border border-gray-300 rounded-md mb-3 flex justify-between",
                  {
                    "border-blue-700":
                      props.currentSessionRecordingEventTimestamp >
                      event.timestamp,
                  }
                )}
                onClick={() => {
                  props.playerInstance.play(
                    event.timestamp - props.sessionRecording.events[0].timestamp
                  );
                }}
              >
                <p className="text-xs ">{getReadableEventName(event)}</p>
                <p className="text-xs text-gray-700">
                  {moment
                    .utc(
                      event.timestamp -
                        props.sessionRecording.events[0].timestamp
                    )
                    .format("HH:mm:ss")}
                </p>
              </div>
            );
          }
        }
      })}
    </div>
  );
}
