import {
  Divider,
  List,
  ListItemButton,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Avatar,
  Collapse,
} from "@mui/material";
import { useNavigate, useMatch, useLocation } from "react-router-dom";
import { BiMessageSquareAdd } from "react-icons/bi";
import {
  HomeIcon,
  InboxArrowDownIcon,
  ArrowLeftStartOnRectangleIcon,
  ChatBubbleBottomCenterTextIcon,
  PuzzlePieceIcon,
  Cog6ToothIcon,
  ChevronUpIcon,
} from "@heroicons/react/24/outline";
import clsx from "clsx";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { findUser } from "../../slices/user.slice";
import {
  Home,
  DirectInbox,
  LogoutCurve,
  ArrowLeft2,
  ArrowRight2,
  User,
  MonitorRecorder
} from "iconsax-react";

export default function NavigationSideBarLeftV2(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (
      sessionStorage.getItem("id") === null ||
      sessionStorage.getItem("access-token") === null
    ) {
      sessionStorage.clear();
      localStorage.clear();
      navigate("/sign-in");
      return;
    }
    if (user.status === "idle") {
      dispatch(
        findUser({
          parameters: {
            id: sessionStorage.getItem("id"),
          },
          navigate,
        })
      );
    }
  }, []);

  return (
    <div className={"w-full h-full flex flex-col"}>
      <div className={"p-3 min-h-[60px] flex items-center"}>
        <img
          alt="usabul"
          className={"h-[30px]"}
          src={
            "https://res.cloudinary.com/geergregrgege/image/upload/v1722772898/assets/images/usabul_logo_blue_bs79du.png"
          }
        />
      </div>

      <div className={"px-3"}>
        <List>
          {[
            {
              label: "Home",
              icon: Home,
              path: "/dashboard/home",
            },
            {
              label: "Users",
              icon: User,
              path: "/dashboard/users",
            },
          ].map((item) => {
            return (
              <ListItemButton
                onClick={() => {
                  navigate(item.path);
                }}
                className={"!rounded-md"}
              >
                <ListItemIcon>
                  <item.icon className={`size-5`} />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{
                    noWrap: true,
                    className: "!text-sm !font-light !text-gray-500",
                  }}
                  primary={item.label}
                />
              </ListItemButton>
            );
          })}
        </List>
      </div>
      <Divider />
      <div className={"flex-grow h-full mt-6 px-3"}>
        <div className={"flex justify-between items-center"}>
          <p className={"text-sm font-semibold"}>Framer Project 01</p>
          <BiMessageSquareAdd className={"text-gray-400"} />
        </div>
        <List className={"!mt-3"}>
          {[
            {
              label: "Feedback",
              icon: ChatBubbleBottomCenterTextIcon,
              path: "/project/feedback/*",
              children: [
                {
                  label: "Feedback",
                  path: "/project/feedback/feedback",
                },
                {
                  label: "Analytics",
                  path: "/project/feedback/analytics",
                },
              ],
            },
            {
              label: "Widgets",
              path: "/project/widget/*",
              icon: PuzzlePieceIcon,
              children: [
                {
                  label: "Button",
                  path: "/project/widget/button",
                },
                {
                  label: "Guest Form",
                  path: "/project/widget/guest-form",
                },
                {
                  label: "Member Form",
                  path: "/project/widget/member-form",
                },
                // {
                //   label: "Security",
                //   path: "/project/widget/security",
                // },
                // {
                //   label: "Developer tools",
                //   path: "/project/widget/developer-tools",
                // },
                {
                  label: "Installation",
                  path: "/project/widget/installation",
                },
              ],
            },
            {
              label: "Session Recording",
              path: "/project/session-recording/*",
              icon: MonitorRecorder,
              children: [
                {
                  label: "Session Recordings",
                  path: "/project/session-recording/session-recordings",
                },
                {
                  label: "Analytics",
                  path: "/project/session-recording/analytics",
                },
              ],
            },
            {
              label: "Settings",
              path: "/project/settings/*",
              icon: Cog6ToothIcon,
              children: [
                {
                  label: "General",
                  path: "/project/settings/general",
                },
                // {
                //   label: "Notifications",
                //   path: "/project/settings/notifications",
                // },
                // {
                //   label: "Integrations",
                //   path: "/project/settings/integrations",
                // },
              ],
            },
          ].map((item, index) => {
            return <NavigationItem item={item} key={index} />;
          })}
        </List>
      </div>

      <Divider />
      <div className={"px-3"}>
        <List>
          <ListItemButton
            alignItems="start"
            classes={{
              selected: "!bg-[#0047EA] !text-white",
            }}
            selected={false}
            onClick={() => {
              navigate("/settings/personal/profile");
            }}
            className={"hover:!bg-gray-100 !rounded-md !font-light"}
          >
            <ListItemAvatar>
              <Avatar className={"!size-5"} src={user?.data?.image} />
            </ListItemAvatar>
            <ListItemText primaryTypographyProps={{ noWrap: true }}>
              {`${user?.data?.firstName} ${user?.data?.lastName}`}
            </ListItemText>
          </ListItemButton>
          <ListItemButton
            alignItems="start"
            onClick={() => {
              navigate("/sign-in");
            }}
            className={"hover:!bg-red-200 !rounded-md !text-red-700"}
          >
            <ListItemIcon>
              <ArrowLeftStartOnRectangleIcon
                className={`size-5 text-red-700`}
              />
            </ListItemIcon>
            <ListItemText primary={"Sign Out"} />
          </ListItemButton>
        </List>
      </div>
    </div>
  );
}

function NavigationItem(props) {
  const navigate = useNavigate();
  const match = !!useMatch(props.item.path);
  const [isOpen, setIsOpen] = useState(match);
  return (
    <>
      <ListItemButton
        key={props.item.label}
        selected={match}
        onClick={() => {
          if (props.item.children) {
            setIsOpen(!isOpen);
          } else {
            navigate(props.item.path);
          }
        }}
        className={clsx("!rounded-md !mb-3", {
          "!bg-[#0047EA] !text-white": match,
          "!bg-gray-100 !text-gray-500": !match,
        })}
      >
        <ListItemIcon>
          <props.item.icon
            className={clsx("size-5", {
              "!text-white": match,
            })}
          />
        </ListItemIcon>
        <ListItemText
          primary={props.item.label}
          primaryTypographyProps={{
            className: `!text-sm !font-light`,
          }}
        />
        <ChevronUpIcon
          className={clsx("size-3", {
            "!rotate-180": !isOpen,
          })}
        />
      </ListItemButton>
      {props.item.children && (
        <Collapse in={match || isOpen}>
          <List
            disablePadding={true}
            className={"!border-l-2 !border-l-gray-300 !px-1.5 !mx-1.5  !mb-3"}
          >
            {props.item.children.map((child, index) => (
              <NavigationItem2 key={index} item={child} />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
}

function NavigationItem2(props) {
  const match = !!useMatch(props.item.path);
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <ListItemButton
      selected={match}
      onClick={() => {
        navigate(`${props.item.path}${location.search}`);
      }}
      className={clsx("!rounded-md !mb-1", {
        "!bg-[#FFFAC3] !text-black": match,
        "!text-gray-500 !bg-gray-50": !match,
      })}
    >
      <ListItemText
        primary={props.item.label}
        primaryTypographyProps={{
          className: clsx("!text-xs", {
            "!font-light": !match,
          }),
        }}
      />
    </ListItemButton>
  );
}
