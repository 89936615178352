import { useState, useEffect, useRef } from "react";
import "rrweb-player/dist/style.css";
import { Replayer } from "rrweb";
import { IconButton } from "@chakra-ui/react";
import { Play, Repeat, Pause } from "iconsax-react";
import { TbRewindBackward10 } from "react-icons/tb";
import { TbRewindForward10 } from "react-icons/tb";
import { ProgressRoot, ProgressBar } from "../../components/ui/progress";

let audioContext;

function unlockAudioContext() {
  if (!audioContext) {
    audioContext = new (window.AudioContext || window.webkitAudioContext)();
    console.log("AudioContext unlocked through passive interaction.");
  }
}

function playSound() {
  if (!audioContext) {
    console.warn("AudioContext not unlocked yet.");
    return;
  }

  const sound = new Audio(
    "https://res.cloudinary.com/geergregrgege/video/upload/v1735043799/clicky-mouse-click-182496_vmfftf.mp3"
  );

  sound.play();
}

// Attach to passive user events
document.addEventListener("mousemove", unlockAudioContext, { once: true });
document.addEventListener("scroll", unlockAudioContext, { once: true });
document.addEventListener("touchstart", unlockAudioContext, { once: true });

export default function Player(props) {
  const { events } = props.sessionRecording;
  const replayerRef = useRef(null); // Reference to the DOM container
  const playerInstance = useRef(null);
  const [width, setWidth] = useState(100);
  const [height, setHeight] = useState(200);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  useEffect(() => {
    if (playerInstance.current === null) {
      const replayer = new Replayer(events, {
        root: replayerRef.current,
        skipInactive: true,
      });
      replayer.play();
      replayer.on("event-cast", (event) => {
        props.setCurrentSessionRecordingEvent((data) => {
          return { ...event };
        });
        props.setCurrentSessionRecordingEventTimestamp((data) => {
          return event.timestamp;
        });
        // if (event.type === 3 && event.data.type === 2) {
        //   playSound();
        // }
      });
      replayer.on("resize", (payload) => {
        console.log("Resize event on replayer:", payload);
        let parentElement = replayerRef.current.parentElement;
        let childElement = replayerRef.current.firstChild;

        let scale = 1;

        function rec() {
          let widthCheckPass = false;
          let heightCheckPass = false;

          if (
            childElement.getBoundingClientRect().width <
            parentElement.getBoundingClientRect().width - 50
          ) {
            widthCheckPass = true;
          }
          if (
            childElement.getBoundingClientRect().height <
            parentElement.getBoundingClientRect().height - 50
          ) {
            heightCheckPass = true;
          }
          if (widthCheckPass && heightCheckPass) {
            // replayerRef.current.firstChild.style.scale = 1;
            setWidth(childElement.getBoundingClientRect().width);
            setHeight(childElement.getBoundingClientRect().height);
            return;
          }

          if (!widthCheckPass || !heightCheckPass) {
            scale -= 0.05;
            childElement.style.scale = scale;
            rec();
          }
        }
        rec();
        console.log(replayerRef.current.parentElement.getBoundingClientRect());
        console.log(replayerRef.current.firstChild.getBoundingClientRect());
        replayerRef.current.firstChild.style.top = 0;
        replayerRef.current.firstChild.style.left = 0;
      });

      replayer.on("start", () => {
        setIsOpen(true);
      });
      replayer.on("pause", () => {
        setIsOpen(false);
        setIsOpen2(true);
      });
      playerInstance.current = replayer;
      props.setPlayerInstance(replayer);
    }
  }, []);

  return (
    <div className="h-full w-full flex flex-col">
      <div className="flex-grow w-full h-full flex justify-center items-center">
        <div
          ref={replayerRef}
          style={{
            width: width,
            height: height,
          }}
          className={"!overflow-hidden"}
        ></div>
      </div>
      <div className="w-full py-3 bg-white border-t border-t-gray-300">
        <div className="flex justify-center w-full py-3 px-6">
          <ProgressRoot
            value={
              props.currentSessionRecordingEventTimestamp -
              props.sessionRecording.events[0].timestamp
            }
            max={
              props.sessionRecording.events[
                props.sessionRecording.events.length - 1
              ].timestamp - props.sessionRecording.events[0].timestamp
            }
            w={"100%"}
            colorPalette={"blue"}
          >
            <ProgressBar />
          </ProgressRoot>
        </div>
        <div className="w-full flex justify-center items-center px-6 gap-6">
          <IconButton
            borderWidth={1}
            size={"xs"}
            _hover={{
              borderColor: "blue.500",
              bg: "blue.100",
              cursor: "pointer",
            }}
            variant={"solid"}
          >
            <TbRewindBackward10 />
          </IconButton>
          {isOpen2 && (
            <IconButton
              borderWidth={1}
              _hover={{
                borderColor: "blue.500",
                bg: "blue.100",
                cursor: "pointer",
              }}
              variant={"solid"}
              onClick={() => {
                playerInstance.current.play();
              }}
            >
              <Play variant="Bold" />
            </IconButton>
          )}

          <IconButton
            borderWidth={1}
            _hover={{
              borderColor: "blue.500",
              bg: "blue.100",
              cursor: "pointer",
            }}
            variant={"solid"}
          >
            <Repeat />
          </IconButton>
          {isOpen && (
            <IconButton
              borderWidth={1}
              _hover={{
                borderColor: "blue.500",
                bg: "blue.100",
                cursor: "pointer",
              }}
              variant={"solid"}
              onClick={() => {
                playerInstance.current.pause();
              }}
            >
              <Pause variant="Bold" />
            </IconButton>
          )}

          <IconButton
            borderWidth={1}
            size={"xs"}
            _hover={{
              borderColor: "blue.500",
              bg: "blue.100",
              cursor: "pointer",
            }}
            variant={"solid"}
          >
            <TbRewindForward10 />
          </IconButton>
        </div>
      </div>
    </div>
  );
}
