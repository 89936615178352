import { Button, Checkbox, Divider, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import clsx from "clsx";
import { findUser } from "../../../../slices/user.slice";
import { useNavigate, useSearchParams } from "react-router-dom";
import services from "../../../../services";
import IssueView from "../../../../components/dashboard/inbox/IssueView";

export default function Feedbacks() {
  const user = useSelector((state) => state.user);
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [issues, setIssues] = useState([]);
  const [selectedIssue, setSelectedIssue] = useState(null);
  const [isLoading, setIsLoading] = useState([true]);
  const [isOpen, setIsOpen] = useState([true]);

  useEffect(() => {
    if (user.status === "idle") {
      dispatch(
        findUser({
          parameters: {
            id: sessionStorage.getItem("id"),
          },
          navigate,
        }),
      );
    }
    if (user.status === "success") {
      console.log("USER FULFILLED");
    }
  }, [user.status]);

  useEffect(() => {
    async function setup() {
      const response = await services.accounts.projects.single.issues.find({
        id: searchParams.get("id"),
      });
      if (response.status === 200) {
        if (response.data.data.issues[0] && !selectedIssue) {
          setSelectedIssue(response.data.data.issues[0]);
        }
        setIssues((issues) => [...response.data.data.issues]);
      }

      setIsLoading((isLoading) => {
        setIsLoading[0] = false;
        return [...isLoading];
      });
    }

    setup();
  }, []);

  useEffect(() => {
    setIsOpen((isOpen) => {
      isOpen[0] = false;
      return [...isOpen];
    });

    setTimeout(() => {
      setIsOpen((isOpen) => {
        isOpen[0] = true;
        return [...isOpen];
      });
    }, 100);
  }, [selectedIssue]);

  return (
    <>
      <div>
        <h1 className="text-2xl font-semibold mt-6">Feedbacks</h1>
        <p className="text-xs font-light mt-3 text-gray-600">
          Get some insight on your reporting
        </p>
      </div>
      {issues.length > 0 ? (
        <div
          className={
            "bg-white h-full border border-gray-300 overflow-hidden mt-6"
          }
        >
          <div className={"flex w-full h-full"}>
            <div
              className={
                "bg-neutral-50 w-full max-w-[400px] h-full overflow-y-auto no-scrollbar"
              }
            >
              <div className={"px-6 py-3 flex items-center justify-between"}>
                <div></div>
                <p
                  className={"text-xs font-light"}
                >{`${issues.length} Items`}</p>
              </div>
              <div className={"px-0"}>
                {issues.map((issue) => {
                  return (
                    <>
                      <Divider />
                      <div
                        className={clsx(
                          "px-6 py-3 flex justify-between  cursor-pointer",
                          {
                            "bg-blue-700 text-white":
                              selectedIssue._id === issue._id,
                            "hover:bg-gray-200":
                              selectedIssue._id !== issue._id,
                          },
                        )}
                        onClick={() => {
                          setSelectedIssue(issue);
                        }}
                      >
                        <div className={"flex items-center gap-x-2"}>
                          <div>
                            <p className={"text-xs font-light"}>
                              {issue.project.title}
                            </p>
                            <p className={"text-sm"}>{issue.summary}</p>
                          </div>
                        </div>
                        <div className={"flex"}>
                          <p className={"text-xs font-light"}>
                            {issue.createdBy.emailAddress}
                          </p>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
            <div className={"w-full h-full overflow-y-auto no-scrollbar"}>
              {isOpen[0] === true ? (
                <IssueView issue={selectedIssue} />
              ) : (
                <p>Getting your issue</p>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full mt-6 py-6 bg-white rounded-md">
          <div className={"flex flex-col items-center justify-center"}>
            <p className={"text-lg"}>You currently have no submitted issue</p>
            <p className={"text-sm text-gray-500 my-3"}>
              Submit an issue to get started
            </p>
            {/*<Button*/}
            {/*  variant={"contained"}*/}
            {/*  className={"mx-auto !bg-blue-700"}*/}
            {/*  onClick={() => {*/}
            {/*    window.open(*/}
            {/*      `https://${project.data.website}`,*/}
            {/*      "_blank",*/}
            {/*      "noopener,noreferrer",*/}
            {/*    );*/}
            {/*  }}*/}
            {/*>*/}
            {/*  Submit an issue*/}
            {/*</Button>*/}
          </div>
        </div>
      )}
    </>
  );
}
